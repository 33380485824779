import classNames from 'classnames';
import React, {
    ChangeEvent,
    Component,
    KeyboardEvent,
    MouseEvent,
    ReactNode,
    createRef,
} from 'react';

import { Duration } from '@lib/entity/duration';
import { RelativeLayout } from '@lib/layout/relativeLayout';
import { DateLabelPickerUI } from '@lib/ui/DateLabelPicker';
import { MaterialIconUI } from '@lib/ui/MaterialIcon';
import { TooltipUI } from '@lib/ui/Tooltip';

import { taskGoalLengthLimit } from '@core/config/config';
import { Deps } from '@core/dep/deps';
import { UpdateTaskInput } from '@core/entity/input';
import { Task } from '@core/entity/task';
import { TaskAction } from '@core/entity/taskAction';
import { TaskStatus } from '@core/entity/taskStatus';
import { StateSyncer } from '@core/storage/syncer/stateSyncer';

import { ContextMenuComponent } from './ContextMenu.component';
import { DurationInputUI } from './DurationInput.ui';
import styles from './InlineTask.module.scss';
import { SelectTaskOwnersPopupComponent } from './SelectTaskOwnersPopupComponent';
import { TaskLinkIconUI } from './TaskLinkIcon';
import { UserProfileUI } from './UserProfile';
import { dueDate, getUserShortName } from './format';

const statusNames: Record<TaskStatus, string> = {
    TODO: 'Todo',
    PAUSED: 'Paused',
    IN_PROGRESS: 'In Progress',
    BLOCKED: 'Blocked',
    AWAITING: 'Awaiting',
    DELIVERED: 'Delivered',
};

interface Props {
    deps: Deps;
    relativeLayout: RelativeLayout;
    task: Task;
    filterTaskAction?: (action: TaskAction) => boolean;
    showStatus?: boolean;
    numActionColumns?: number;
    currentClientId?: number;
    onStartEditingTask?: () => void;
    onFinishEditingTask?: () => void;
    onUpdateTask?: (taskId: number, task: UpdateTaskInput) => void;
    onStartTask?: (taskId: number) => void;
    onDeleteTask?: (taskId: number) => void;
    onCompleteTask?: (taskId: number) => void;
    onViewTaskDetail?: (taskId: number) => void;
    onReportTaskBlocked?: (taskId: number) => void;
}

interface State {
    isEditingGoal: boolean;
    isSelectingOwner: boolean;
    updatedGoal?: string;
}

export class InlineTaskUI extends Component<Props, State> {
    private readonly stateSyncer: StateSyncer;
    private getBoundingClientRect?: () => DOMRect;
    private readonly selectTaskOwnersPopupRef =
        createRef<SelectTaskOwnersPopupComponent>();
    private readonly taskIdContextMenuRef = createRef<ContextMenuComponent>();

    constructor(props: Props) {
        super(props);
        this.stateSyncer = props.deps.stateSyncer;
        this.state = {
            isSelectingOwner: false,
            isEditingGoal: false,
        };
    }

    public render() {
        const { draggingBy } = this.props.task;

        const showDraggingBy =
            draggingBy && draggingBy.id !== this.props.currentClientId;

        return (
            <>
                <div className={styles.InlineTask}>
                    <div
                        className={`${styles.Task} ${classNames({
                            [styles.Dragging]: showDraggingBy,
                        })}`}
                    >
                        <div className={styles.LeftSection}>
                            <div className={styles.TopRow}>
                                <div
                                    className={`${styles.Attribute} ${styles.Id}`}
                                    onContextMenu={this.onTaskIdContextMenu}
                                >
                                    {this.props.task.id}
                                </div>

                                {this.props.showStatus && (
                                    <div
                                        className={`${styles.Attribute} ${
                                            styles.Status
                                        } ${styles[this.props.task.status]}`}
                                    >
                                        {statusNames[this.props.task.status]}
                                    </div>
                                )}
                            </div>
                            {this.renderGoal()}
                            <div className={styles.BottomRow}>
                                <div className={styles.Attribute}>
                                    {this.renderDueAt()}
                                </div>
                                <div className={styles.Attribute}>
                                    {this.renderEffort()}
                                </div>

                                {this.props.task.links.map((link) => (
                                    <div
                                        className={styles.Attribute}
                                        key={link.id}
                                    >
                                        <TaskLinkIconUI link={link} />
                                    </div>
                                ))}
                            </div>
                            {this.props.task.awaitForTasks?.length > 0 &&
                                this.renderAwaitForTasks()}
                        </div>
                        <div
                            className={styles.ActionsSection}
                            style={{
                                gridTemplateColumns: `repeat(${
                                    this.props.numActionColumns || 3
                                }, 1fr)`,
                            }}
                        >
                            {this.renderActions()}
                        </div>
                    </div>
                    {showDraggingBy && (
                        <div className={styles.DraggingBySection}>
                            {getUserShortName(
                                draggingBy.user.firstName,
                                draggingBy.user.lastName,
                            )}{' '}
                            is dragging
                        </div>
                    )}
                </div>
                <SelectTaskOwnersPopupComponent
                    ref={this.selectTaskOwnersPopupRef}
                    deps={this.props.deps}
                    findTaskOwnersAlignTargetBoundBox={
                        this.getBoundingClientRect
                    }
                    onClose={this.onTaskOwnersPopupClose}
                    onSelectOwner={this.onTaskOwnerSelected}
                    selectedOwnerId={this.props.task.owner?.id}
                />
                <ContextMenuComponent
                    ref={this.taskIdContextMenuRef}
                    menuItems={Object.entries(
                        this.props.deps.uiRegistry.taskIdActions,
                    ).map(([key, value]) => ({
                        key,
                        label: value.readableName,
                        action: () => value.execute(this.props.task.id),
                    }))}
                />
            </>
        );
    }

    private renderGoal(): ReactNode {
        return this.state.isEditingGoal ? (
            <textarea
                autoFocus
                maxLength={taskGoalLengthLimit}
                className={styles.GoalTextField}
                value={this.state.updatedGoal || ''}
                onChange={this.onGoalChange}
                onKeyDown={this.onGoalKeyDown}
                onBlur={this.onGoalBlur}
            />
        ) : (
            <div className={styles.Goal} onDoubleClick={this.onGoalDoubleClick}>
                {this.props.task.goal || (
                    <span className={styles.Placeholder}>
                        Set a goal for me
                    </span>
                )}
            </div>
        );
    }

    private onTaskIdContextMenu = (event: MouseEvent) => {
        event.preventDefault();
        this.taskIdContextMenuRef.current?.open(event.clientY, event.clientX);
    };

    private renderDueAt = () => {
        const { dueAt, deliveredAt } = this.props.task;
        const now = new Date();
        const endOfDueAt = byTheEndOf(dueAt)?.getTime();
        let warn = false;
        if (endOfDueAt) {
            warn = endOfDueAt < now.getTime();
            if (deliveredAt && deliveredAt.getTime() <= endOfDueAt) {
                warn = false;
            }
        }

        return (
            <DateLabelPickerUI
                relativeLayout={this.props.relativeLayout}
                value={dueAt}
                isWarn={warn}
                shownValue={dueAt && dueDate(dueAt)}
                onChange={this.handleEndDateChanged}
            />
        );
    };

    private handleEndDateChanged = (date?: Date) => {
        if (!date) {
            return;
        }

        this.props.onUpdateTask?.call(this, this.props.task.id, {
            ...this.getUpdatedTask(),
            dueAt: date,
        });
    };

    private onTaskOwnerSelected = (ownerUserId?: number) => {
        this.setState({
            isSelectingOwner: false,
        });

        this.stateSyncer.updateTask(this.props.task.id, {
            ...this.getUpdatedTask(),
            ownerUserId,
        });
    };

    private onTaskOwnersPopupClose = () => {
        this.setState({
            isSelectingOwner: false,
        });
    };

    private renderEffort = () => {
        const { effort } = this.props.task;
        return (
            <DurationInputUI
                relativeLayout={this.props.relativeLayout}
                duration={effort}
                icon={
                    <MaterialIconUI iconStyle={'outlined'}>
                        weight
                    </MaterialIconUI>
                }
                canClear={true}
                onPopupOpen={this.onEffortPopupOpen}
                onPopupClose={this.onEffortPopupClose}
                onDurationChange={this.onEffortChange}
            />
        );
    };

    private renderAwaitForTasks(): ReactNode {
        return (
            <div className={styles.AwaitForTasksSection}>
                <div className={styles.AwaitForIcon}>
                    <MaterialIconUI iconStyle={'rounded'}>
                        hourglass_top
                    </MaterialIconUI>
                </div>
                <div className={styles.AwaitForTasks}>
                    {this.props.task.awaitForTasks.map(this.renderAwaitForTask)}
                </div>
            </div>
        );
    }

    private renderAwaitForTask = (task: Task, index: number): ReactNode => {
        return (
            <div
                key={index}
                className={`${styles.AwaitForTask} ${classNames({
                    [styles.Delivered]: task.status === 'DELIVERED',
                })}`}
            >
                {task.id}
            </div>
        );
    };

    private renderActions(): ReactNode[] {
        let actions = this.props.task.availableActions.concat('VIEW_DETAIL');
        if (this.props.filterTaskAction) {
            actions = actions.filter(this.props.filterTaskAction);
        }

        return actions.map(this.renderAction);
    }

    private renderAction = (action: TaskAction, index: number): ReactNode => {
        switch (action) {
            case 'START':
                return this.renderStartTask(index);
            case 'MARK_COMPLETE':
                return this.renderCompleteTask(index);
            case 'REPORT_BLOCKED':
                return this.renderReportBlocked(index);
            case 'ADD_AWAIT_FOR_TASK':
                return this.renderAddAwaitForTask(index);
            case 'ASSIGN_OWNER':
                return this.renderAssignOwner(index);
            case 'DELETE':
                return this.renderDeleteTask(index);
            case 'VIEW_DETAIL':
                return this.renderViewTaskDetail(index);
        }
    };

    private renderStartTask(index: number): ReactNode {
        return (
            <div key={index}>
                <TooltipUI message={'Start'}>
                    <div
                        className={`${styles.Action}`}
                        onClick={this.onStartTaskClick}
                    >
                        <div className={styles.StartTaskIcon}>
                            <MaterialIconUI>play_arrow</MaterialIconUI>
                        </div>
                    </div>
                </TooltipUI>
            </div>
        );
    }

    private renderReportBlocked(index: number): ReactNode {
        return (
            <div key={index}>
                <TooltipUI message={'Report Blocked'}>
                    <div
                        className={`${styles.Action}`}
                        onClick={this.onReportTaskBlockedClick}
                    >
                        <div className={styles.ReportTaskBlockedIcon}>
                            <MaterialIconUI>priority_high</MaterialIconUI>
                        </div>
                    </div>
                </TooltipUI>
            </div>
        );
    }

    private renderAddAwaitForTask(index: number) {
        return (
            <div key={index}>
                <TooltipUI message={'Await'}>
                    <div
                        className={`${styles.Action}`}
                        onClick={this.onAddAwaitForTaskClick}
                    >
                        <div className={`${styles.AddAwaitForTaskIcon}`}>
                            <MaterialIconUI iconStyle={'rounded'}>
                                move_up
                            </MaterialIconUI>
                        </div>
                    </div>
                </TooltipUI>
            </div>
        );
    }

    private renderCompleteTask(index: number): ReactNode {
        return (
            <div key={index}>
                <TooltipUI message={'Complete'}>
                    <div
                        className={`${styles.Action}`}
                        onClick={this.onCompleteTaskClick}
                    >
                        <div className={` ${styles.CompleteTaskIcon}`}>
                            <MaterialIconUI>task_alt</MaterialIconUI>
                        </div>
                    </div>
                </TooltipUI>
            </div>
        );
    }

    private renderDeleteTask(index: number): ReactNode {
        return (
            <div key={index}>
                <TooltipUI message={'Delete'}>
                    <div
                        className={`${styles.Action}`}
                        onClick={this.onDeleteTaskClick}
                    >
                        <div className={` ${styles.DeleteTaskIcon}`}>
                            <MaterialIconUI>close</MaterialIconUI>
                        </div>
                    </div>
                </TooltipUI>
            </div>
        );
    }

    private renderAssignOwner(index: number): ReactNode {
        const owner = this.props.task.owner;
        const name = owner
            ? getUserShortName(owner.firstName, owner.lastName)
            : 'Unassigned';
        return (
            <div key={index} className={styles.AssignOwner}>
                <TooltipUI message={name}>
                    <div
                        className={`${styles.Action} ${classNames({
                            [styles.Active]: this.state.isSelectingOwner,
                        })}`}
                        onClick={this.onAssignOwnerClick}
                    >
                        <div className={` ${styles.AssignOwnerIcon}`}>
                            {owner ? (
                                <UserProfileUI user={owner} />
                            ) : (
                                <MaterialIconUI>face</MaterialIconUI>
                            )}
                        </div>
                    </div>
                </TooltipUI>
            </div>
        );
    }

    private renderViewTaskDetail(index: number): ReactNode {
        return (
            <div key={index}>
                <TooltipUI message={'Detail'}>
                    <div
                        className={`${styles.Action}`}
                        onClick={this.onViewTaskDetailClick}
                    >
                        <div className={styles.ViewTaskDetailIcon}>
                            <MaterialIconUI>settings</MaterialIconUI>
                        </div>
                    </div>
                </TooltipUI>
            </div>
        );
    }

    private onStartTaskClick = () => {
        this.props.onStartTask?.call(null, this.props.task.id);
    };

    private onReportTaskBlockedClick = () => {
        this.props.onReportTaskBlocked?.call(null, this.props.task.id);
    };

    private onCompleteTaskClick = () => {
        this.props.onCompleteTask?.call(null, this.props.task.id);
    };

    private onDeleteTaskClick = () => {
        this.props.onDeleteTask?.call(null, this.props.task.id);
    };

    private onViewTaskDetailClick = () => {
        this.props.onViewTaskDetail?.call(null, this.props.task.id);
    };

    private onAssignOwnerClick = (event: MouseEvent<HTMLDivElement>) => {
        const element = event.currentTarget as HTMLDivElement;
        this.getBoundingClientRect =
            element.getBoundingClientRect.bind(element);
        this.selectTaskOwnersPopupRef?.current?.show.call(null);
        this.setState({
            isSelectingOwner: true,
        });
    };

    private onAddAwaitForTaskClick = (event: MouseEvent<HTMLDivElement>) => {
        // TODO: allow user to pick awaiting task
    };

    private onGoalBlur = () => {
        this.finishEditingGoal();
    };

    private onGoalKeyDown = (
        event: KeyboardEvent<HTMLTextAreaElement>,
    ): void => {
        switch (event.key) {
            case 'Escape':
                this.finishEditingGoal();
                return;
        }
    };

    private onGoalChange = (event: ChangeEvent<HTMLTextAreaElement>): void => {
        this.setState({
            updatedGoal: event.target.value,
        });
    };

    private onGoalDoubleClick = () => {
        this.props.onStartEditingTask?.call(null);
        this.setState({
            isEditingGoal: true,
            updatedGoal: this.props.task.goal,
        });
    };

    private finishEditingGoal() {
        this.props.onUpdateTask?.call(
            null,
            this.props.task.id,
            this.getUpdatedTask(),
        );
        this.setState({
            isEditingGoal: false,
            updatedGoal: undefined,
        });
        this.props.onFinishEditingTask?.call(null);
    }

    private getUpdatedTask(): UpdateTaskInput {
        return {
            goal: this.state.updatedGoal || this.props.task.goal,
            context: this.props.task.context,
            ownerUserId: this.props.task.owner?.id,
            owningTeamId: this.props.task.owningTeam.id,
            effort: this.props.task.effort,
            dueAt: this.props.task.dueAt,
        };
    }

    private onEffortPopupOpen = () => {
        this.props.onStartEditingTask?.call(null);
    };

    private onEffortPopupClose = () => {
        this.props.onFinishEditingTask?.call(null);
    };

    private onEffortChange = (effort?: Duration) => {
        this.props.onUpdateTask?.call(this, this.props.task.id, {
            ...this.getUpdatedTask(),
            effort: effort,
        });
    };
}

function byTheEndOf(date?: Date): Date | undefined {
    if (!date) {
        return undefined;
    }

    const newDate = new Date(date.getTime());
    newDate.setHours(23);
    newDate.setMinutes(59);
    newDate.setSeconds(59);
    return newDate;
}
