export const BodyProp = 'Body';
export const MethodProp = 'Method';
export const HeadersProp = 'Headers';
export const CauseProp = 'Cause';
export const MessageProp = 'Message';
export const HappenAtProp = 'HappenAt';
export const SeverityProp = 'Severity';
export const FileNameProp = 'FileName';
export const LineNumberProp = 'LineNumber';
export const RequestIdProp = 'RequestId';
export const ServiceNameProp = 'ServiceName';
export const StageProp = 'Stage';
export const PathProp = 'Path';
export const ProtocolProp = 'Protocol';
export const CommitProp = 'Commit';
export const HostProp = 'Host';
export const ErrCodeProp = 'ErrorCode';
export const ErrCategoryProp = 'ErrorCategory';

export type Props = Record<string, any>;
