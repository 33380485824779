import React, { Component, createRef } from 'react';

import { MaterialIconUI } from '@lib/ui/MaterialIcon';

import styles from './DropDownList.module.scss';

export interface Option {
    key: string;
    description: string;
}

interface Props {
    selectOptionKey?: string;
    options: Option[];
    onSelectOption?: (optionKey: string) => void;
}

interface State {
    selectedOption?: Option;
    showAvailableOptions: boolean;
}

export class DropDownList extends Component<Props, State> {
    private readonly ref = createRef<HTMLDivElement>();

    constructor(props: Props) {
        super(props);

        const options: Record<string, Option> = {};
        for (const option of this.props.options) {
            options[option.key] = option;
        }
        this.state = {
            selectedOption: this.props.selectOptionKey
                ? options[this.props.selectOptionKey]
                : undefined,
            showAvailableOptions: false,
        };
    }

    public get value(): string | undefined {
        return this.state.selectedOption?.key;
    }

    public render() {
        return (
            <div
                role={'listbox'}
                ref={this.ref}
                className={styles.DropDownList}
                tabIndex={-1}
                onBlur={this.onDropDownBlur}
            >
                <div
                    className={styles.SelectedOption}
                    onClick={this.onSelectedOptionClick}
                >
                    <div role={'listitem'} className={styles.Option}>
                        {this.state.selectedOption &&
                            this.state.selectedOption.description}
                    </div>
                    <div role={'button'} className={styles.DropDownButton}>
                        <MaterialIconUI>arrow_drop_down</MaterialIconUI>
                    </div>
                </div>
                {this.state.showAvailableOptions && (
                    <div className={styles.AvailableOptions}>
                        {this.props.options.map(this.renderOption)}
                    </div>
                )}
            </div>
        );
    }

    private renderOption = (option: Option, index: number) => {
        return (
            <div
                role={'listitem'}
                aria-label={option.description}
                key={index}
                className={styles.Option}
                onClick={this.onOptionClick(option)}
            >
                {option.description}
            </div>
        );
    };

    private onOptionClick = (option: Option) => {
        return () => {
            this.setState({
                selectedOption: option,
                showAvailableOptions: false,
            });

            if (this.state.selectedOption === option) {
                return;
            }
            this.props.onSelectOption?.call(null, option.key);
        };
    };

    private onSelectedOptionClick = () => {
        this.toggleAvailableActions();
    };

    private toggleAvailableActions() {
        this.setState({
            showAvailableOptions: !this.state.showAvailableOptions,
        });
        if (this.state.showAvailableOptions) {
            this.ref.current?.focus();
        }
    }

    private onDropDownBlur = () => {
        this.setState({
            showAvailableOptions: false,
        });
    };
}
